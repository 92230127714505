import AuthModule from '@/store/modules/auth';
import { abilities } from '@/plugins/casl/abilities';
import router from '@/router/';
import { MenuButton } from '@/types/navbar/';

/**
 * Checks the user permissions and returns the MenuButtons that the user has acces to
 * 
 * @param menuButtons Array of MenuButton objects 
 * 
 * @returns An array of MenuButtons that the user has acces to
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
export function filterMenuButtons(menuButtons: Array<MenuButton>): Array<MenuButton> {
    return menuButtons.filter(menuButton => {
        const routeObject = router.resolve(menuButton.to);
        return !(routeObject.route.matched.some(
            record => (record.meta.requiresLogin && !AuthModule.isAuthenticated) || 
            (record.meta.requiredPermission && record.meta.requiredPermission.action && record.meta.requiredPermission.subject && !abilities.can(record.meta.requiredPermission.action, record.meta.requiredPermission.subject))))
    });
}