








































import { Component, Vue } from 'vue-property-decorator';
import appNavbar from './Navbar.vue';
import { Carousel } from '@/types/cms/components/';
import axios, { APIResponse } from '@/plugins/axios';

/**
 * Header component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        appNavbar
    }
})
export default class Header extends Vue {
    // variable with carousel component data
    private carouselComponent: Carousel = {
        id: -1,
        cycle: false,
        items: []
    }

    /**
     * Function will be executed on component load
     * Fetches component data
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        this.carouselComponent = await this.fetchCarouselData();
    }

    /**
     * Fetches carousel content from API
     * 
     * @returns Carousel
     * 
     * @author Kevin Danen <danne@skiba-procomputer.de>
     */
    private async fetchCarouselData(): Promise<Carousel> {
        try {
            const response = await axios.get<APIResponse<Carousel>>('/cms/components/carousel')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.carouselComponent;
        }
    }

    /*
     * @param relativeUrl relative image url
     *
     * @returns API url + relative image url
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private getFullImageUrl(relativeUrl: string): string {
        return process.env.VUE_APP_API_URL + relativeUrl;
    }
}
