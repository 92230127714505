























































































import { Component, Vue } from 'vue-property-decorator';
import AuthModule from '@/store/modules/auth';
import { abilities } from '@/plugins/casl/abilities';
import * as navbarHelper from '@/utils/navbarhelper';
import { MenuButton } from '@/types/navbar/';
import { getCMSPages } from '@/utils/cms/';

/**
 * Navbar component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class Navbar extends Vue {
    // Routes that the user has access to
    private filteredMenuButtons: Array<MenuButton> = [];

    /**
     * Function will be called on component load
     * Calls filterMenuButtons once and when abilities are updated
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        this.filterMenuButtons();
        abilities.on('updated', () => {
            this.filterMenuButtons();
        });
    }
    
    /**
     * Saves the routes in filteredMenuButtons that the user has access to
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private filterMenuButtons() {
        const menuButtons: Array<MenuButton> = [];

        // add cms pages
        getCMSPages().forEach(page => {
            if (!page.showInNavbar) return;

            menuButtons.push({
                to: { name: page.routeName },
                exact: page.exactActiveHighlighting,
                text: page.name
            });
        });

        // Add normal pages
        menuButtons.push({
            to: { name: 'contactPage' },
            exact: true,
            text: this.$t('buttons.contactPage').toString()
        })

        // add admin route
        menuButtons.push({
            to: { name: 'cmsAdmin' },
            exact: false,
            text: this.$t('buttons.cmsAdmin').toString()
        });

        this.filteredMenuButtons = navbarHelper.filterMenuButtons(menuButtons); 
    }
    
    /**
     * Logs the user out
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private logout() {
        AuthModule.logout();
    }

    /**
     * @returns a boolean that represents whether the user is logged in
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get isAuthenticated(): boolean {
        return AuthModule.isAuthenticated;
    }
}
